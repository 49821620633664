// filepath: src/components/DistanceCalculator.js
import LoadingSpin from "@/ui/LoadingSpin";
import { api, RouterOutputs } from "@/utils/trpc";
import { LoadScript } from "@react-google-maps/api";
import { formatDuration, intervalToDuration } from "date-fns/esm";
import { FC, useState } from "react";
import { FaDirections } from "react-icons/fa";

type Props = {
  expenseId: string;
  tripeDetails: RouterOutputs["expenses"]["get"]["tripDetails"];
};

const TripDetails: FC<Props> = ({ expenseId, tripeDetails }) => {
  const [details, setDetails] = useState(tripeDetails);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  const add = api.expenses.tripDetails.add.useMutation();

  const handleCalculateDistance = async () => {
    setLoading(true);
    const service = new window.google.maps.DistanceMatrixService();
    const { rows } = await service.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    const { distance, duration } = rows[0].elements[0];
    setDistance(distance.value);
    setDuration(duration.value);
    setLoading(false);
  };

  const handleSave = async () => {
    const res = await add.mutateAsync({
      expenseId,
      origin,
      destination,
      distance,
      duration,
    });

    setDetails(res);
  };

  const formattedDuration = (duration: number) =>
    formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }), {
      format: ["hours"],
    });

  const formattedDistance = (distance: number) =>
    `${(distance / 1000).toFixed(2)} km`;

  if (details)
    return (
      <div className="grid">
        <p className="label">Trip Details</p>
        <div className="flex items-center gap-2">
          <p className="text-lg flex-1 btn btn-sm input-bordered">
            {details.origin}
          </p>
          <FaDirections />
          <p className="text-lg flex-1 btn btn-sm input-bordered">
            {details.destination}
          </p>
        </div>
        <div className="mt-4 grid">
          <div className="flex gap-2 items-center">
            <div className="badge badge-neutral flex-1">
              Duration: {formattedDuration(details.duration)}
            </div>
            <div className="badge badge-neutral flex-1">
              Distance: {formattedDistance(details.distance)}
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <LoadScript googleMapsApiKey="AIzaSyCB8-iAp34c08GeIFvk8HZyM0mowauD9nQ">
      <div className="grid">
        <p className="label">Add Trip Details</p>
        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Enter origin"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
            className="input input-sm input-bordered flex-1"
          />
          <FaDirections />
          <input
            type="text"
            placeholder="Enter destination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            className="input input-sm input-bordered flex-1"
          />
        </div>
        {!distance ? (
          <button
            onClick={handleCalculateDistance}
            className="btn btn-sm btn-secondary mt-4"
          >
            <LoadingSpin loading={loading} />
            Calculate Distance
          </button>
        ) : (
          <div className="mt-4 grid">
            <div className="flex gap-2 items-center">
              <div className="badge badge-neutral flex-1">
                Duration: {formattedDuration(duration)}
              </div>
              <div className="badge badge-neutral flex-1">
                Distance: {formattedDistance(distance)}
              </div>
            </div>
            <button
              onClick={handleSave}
              className="btn btn-sm btn-primary mt-4"
            >
              <LoadingSpin loading={add.isLoading} />
              Save
            </button>
          </div>
        )}
      </div>
    </LoadScript>
  );
};

export default TripDetails;
